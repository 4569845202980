import React, { useState } from 'react';
import GridPattern from '../../layouts/GridPattern';
import CustomButton from '../shared/CustomButton';

const getCurrentMonthInFrench = () => {
  const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  return months[new Date().getMonth()];
};

const getNextMonthInFrench = () => {
  const months = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
  ];
  const nextMonth = new Date().getMonth() + 1;
  return months[nextMonth % 12];
};

/**
 * prix Component
 * 
 * @component
 * @description Section présentant les différentes offres tarifaires
 */
const Pricing: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<'standard' | 'growth'>('standard');
  const currentMonth = getCurrentMonthInFrench();
  const nextMonth = getNextMonthInFrench();

  const planDetails = {
    standard: {
      price: '4 497',
      description: 'Une demande à la fois. Pausez ou annulez à tout moment.',
      ideal: 'Idéal pour ceux qui ont besoin d\'un support de recrutement continu.',
      testimonial: {
        name: 'Jean Dupont',
        position: 'Directeur chez EntrepriseX',
        quote: 'Phénoménal, rapide, des recrutements de grande qualité.'
      },
      features: [
        'Service complet',
        'Accès à toute l\'équipe',
        'Mises à jour toutes les 48 heures',
        'Réunions de suivi bihebdomadaires',
        'Gestion de projet experte',
        'Marques/utilisateurs illimités',
        'Communication asynchrone + Chat + Visio + Réunions'
      ]
    },
    growth: {
      price: '7 497',
      description: 'Deux demandes à la fois. Pausez ou annulez à tout moment.',
      ideal: 'Idéal pour ceux qui ont besoin d\'un support de recrutement intensif.',
      testimonial: {
        name: 'Marie Martin',
        position: 'PDG chez SociétéY',
        quote: 'Exceptionnel, rapide, des recrutements de haute qualité.'
      },
      features: [
        'Service complet',
        'Deux demandes simultanées',
        'Mises à jour toutes les 48 heures',
        'Marques/utilisateurs illimités',
        'Gestion de projet experte',
        'Réunions de suivi bihebdomadaires',
        'Communication asynchrone + Chat + Visio + Réunions'
      ]
    }
  };

  return (
    <section id="prix" className="py-20 bg-black">
      <div className="mx-auto" style={{ width: '75%' }}>
        <GridPattern
          dotSize={2}
          dotColor="#333"
          spacing={40}
          opacity={0.07}
          backgroundColor="white"
        >
          <div className="rounded-[40px] py-20">
            <div className="container mx-auto max-w-5xl px-4">
              {/* Section Badge */}
              <div className="flex justify-center mb-4">
                <span className="bg-[#111111] text-white px-4 py-1 rounded-full text-sm">
                  prix
                </span>
              </div>

              {/* Section Title */}
              <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold text-center mb-8 md:mb-16 text-black">
                Prêt à accélérer<br />vos recrutements ?
              </h2>

              {/* prix Cards */}
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 md:gap-8 max-w-4xl mx-auto transform scale-95">
                {/* One-off Card */}
                <div className="bg-white rounded-3xl p-8 relative border border-gray-200 flex flex-col min-h-[750px]">
                  {/* Top Badges */}
                  <div className="flex items-center gap-3 mb-8">
                    <span className="bg-black text-white px-3 py-1 rounded-full text-sm whitespace-nowrap">
                      Offre Unique
                    </span>
                    <span className="bg-red-50 text-red-500 px-3 py-1 rounded-full text-sm whitespace-nowrap">
                      Réservez pour {nextMonth}
                    </span>
                  </div>

                  {/* Price Section */}
                  <div className="mb-4 md:mb-8">
                    <div className="flex items-baseline">
                      <span className="text-2xl md:text-[2.75rem] font-bold text-black">3 997 €</span>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className="mb-8">
                    <p className="text-gray-600 text-lg mb-2">
                      Lancez votre campagne de recrutement en jours, pas en mois.
                    </p>
                    <p className="text-gray-600">
                      Parfait pour pourvoir un poste clé rapidement et efficacement.
                    </p>
                  </div>

                  {/* Testimonial Section */}
                  {/* <div className="mb-8">
                    <div className="bg-gray-50 rounded-2xl p-4">
                      <div className="flex items-center gap-3">
                        <img src="https://randomuser.me/api/portraits/men/8.jpg" alt="Chrys Bader" className="w-11 h-11 rounded-full" />
                        <div className="flex-1">
                          <div className="flex items-center justify-between">
                            <div>
                              <h4 className="font-semibold text-black">Chrys Bader</h4>
                              <p className="text-sm text-gray-600">Co-fondateur & CEO chez Rosebud</p>
                            </div>
                            <svg className="w-5 h-5 text-black" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                            </svg>
                          </div>
                          <p className="text-gray-600 mt-2">"Excellent, patient, professionnel, rapide."</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* CTA Button */}
                  <div className="mb-8">
                    <CustomButton
                      variant="primary"
                      size="md"
                      rounded="full"
                      hoverEffect="both"
                      className="w-full flex items-center justify-center gap-2"
                      onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
                    >
                      <span>Réservez votre place pour {nextMonth}</span>
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                      </svg>
                    </CustomButton>
                  </div>

                  {/* Features */}
                  <div className="flex-1">
                    <h4 className="font-semibold text-black mb-4">Caractéristiques :</h4>
                    <ul className="space-y-3">
                      {[
                        'Rédaction des offres d\'emploi incluse',
                        '4 itérations du profil idéal',
                        'Appel stratégique de démarrage',
                        '2 séries de révisions',
                        'Mises à jour toutes les 48 heures',
                        'Recherche active de candidats',
                        'Présélection et évaluation initiale'
                      ].map((feature, index) => (
                        <li key={index} className="flex items-start gap-2 text-gray-600">
                          <svg className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                          </svg>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Monthly Subscription Card */}
                <div className="bg-black rounded-3xl p-8 relative flex flex-col min-h-[750px]">
                  {/* Top Badges */}
                  <div className="flex items-center justify-end mb-3">
                    <div className="flex gap-1 bg-white rounded-full p-1">
                      <CustomButton
                        variant={selectedPlan === 'standard' ? 'primary' : 'secondary'}
                        size="sm"
                        rounded="full"
                        hoverEffect="none"
                        onClick={() => setSelectedPlan('standard')}
                        className={`${
                          selectedPlan === 'standard' 
                            ? 'bg-black text-white' 
                            : 'bg-transparent text-black hover:bg-gray-100'
                        }`}
                      >
                        Standard
                      </CustomButton>
                      <CustomButton
                        variant={selectedPlan === 'growth' ? 'primary' : 'secondary'}
                        size="sm"
                        rounded="full"
                        hoverEffect="none"
                        onClick={() => setSelectedPlan('growth')}
                        className={`${
                          selectedPlan === 'growth' 
                            ? 'bg-black text-white' 
                            : 'bg-transparent text-black hover:bg-gray-100'
                        }`}
                      >
                        Croissance
                      </CustomButton>
                    </div>
                  </div>

                  {/* Price Section */}
                  <div className="mb-8">
                    <div className="flex items-baseline">
                      <span className="text-2xl md:text-[2.75rem] font-bold">{planDetails[selectedPlan].price} €</span>
                      <span className="text-[#ffffffcc] ml-2">/mois</span>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className="mb-8">
                    <p className="text-[#ffffffcc] text-lg mb-2">
                      {planDetails[selectedPlan].description}
                    </p>
                    <p className="text-[#ffffffcc]">
                      {planDetails[selectedPlan].ideal}
                    </p>
                  </div>

                  {/* Testimonial Section */}
                  {/* <div className="mb-8">
                    <div className="bg-[#111111] rounded-2xl p-4">
                      <div className="flex items-center gap-3">
                        <img 
                          src={`https://randomuser.me/api/portraits/${selectedPlan === 'standard' ? 'men/2' : 'women/2'}.jpg`}
                          alt={planDetails[selectedPlan].testimonial.name} 
                          className="w-11 h-11 rounded-full" 
                        />
                        <div className="flex-1">
                          <div className="flex items-center justify-between">
                            <div>
                              <h4 className="font-semibold">{planDetails[selectedPlan].testimonial.name}</h4>
                              <p className="text-sm text-[#ffffffcc]">{planDetails[selectedPlan].testimonial.position}</p>
                            </div>
                            <svg className="w-5 h-5 text-white" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                            </svg>
                          </div>
                          <p className="text-[#ffffffcc] mt-2">"{planDetails[selectedPlan].testimonial.quote}"</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* CTA Button */}
                  <div className="mb-8">
                    <CustomButton
                      variant="secondary"
                      size="md"
                      rounded="full"
                      hoverEffect="both"
                      className="w-full flex items-center justify-center gap-2 bg-white text-black hover:bg-gray-100"
                      onClick={() => window.open('https://cal.com/clement-s/15min', '_blank')}
                    >
                      <span>Réservez votre place pour {currentMonth}</span>
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                      </svg>
                    </CustomButton>
                  </div>

                  {/* Features */}
                  <div className="flex-1">
                    <h4 className="font-semibold mb-4">Caractéristiques :</h4>
                    <ul className="space-y-3">
                      {planDetails[selectedPlan].features.map((feature, index) => (
                        <li key={index} className="flex items-start gap-2 text-[#ffffffcc]">
                          <svg className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                          </svg>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridPattern>
      </div>
    </section>
  );
};

export default Pricing; 